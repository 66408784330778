.modalHeader {
  background-color: rgb(26, 76, 205) !important;
  height: 60px !important;
}

.modalTitle{
  font-size: 18px;
  font-family: Slightpay-bold;
  margin: 10px 0px 10px 5px;
  font-weight: 400;
}

.modalTitleIcon {
  margin: 0px 8px 3px 0 !important;
}

.modalExit {
  position: absolute !important;
  right: 18px !important;
  top: 18px !important;
  width: 22px !important;
  cursor: pointer !important;
}

.modalBodyContainer {
  padding: 0px !important;
}

.modalBody {
  margin: 0;
  font-family: Slightpay-bold;
}

.scroll {
  overflow: visible;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgb(26, 76, 205) !important;
}

@font-face {
  font-family: 'Slightpay-light';
  src: local('Slightpay-light'), url(../../assets/fonts/gilroy-light.otf) format('opentype');
}

@font-face {
  font-family: 'Slightpay-bold';
  src: local('Slightpay-bold'), url(../../assets/fonts/gilroy-medium.otf) format('opentype');
}