.MuiIconButton-colorSecondary {
    color: rgb(26, 76, 205) !important;
}

.MuiFormControlLabel-label {
    font-family: 'Slightpay-light' !important;
}

@font-face {
    font-family: 'Slightpay-light';
    src: local('Slightpay-light'), url(../../assets/fonts/gilroy-light.otf) format('opentype');
}

@font-face {
    font-family: 'Slightpay-bold';
    src: local('Slightpay-bold'), url(../../assets/fonts/gilroy-medium.otf) format('opentype');
}

/* .head {
    background-color: "#fff";
    position: "sticky";
    top: 0;
    z-index: 10;
} */
.tableEncabezados {
    color: white !important; 
    font-size: 12px !important; 
    font-family: 'Slightpay-bold' !important;
}

.tableCellDatos { 
    font-size: 12px !important;
    font-family: 'Slightpay-bold' !important;
}
.tableHead {
    background-color: rgb(26, 76, 205); 
    font-size: 8px 
}

.btnBuscar{
    background-color: rgb(26, 76, 205) !important;;
     color: white !important;
     font-size: 12px !important;
    font-family: 'Slightpay-bold' !important 
}