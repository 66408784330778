.MuiPaper-rounded {
    margin-bottom: 25px !important;
}

@font-face {
    font-family: 'Slightpay-light';
    src: local('Slightpay-light'), url(../assets/fonts/gilroy-light.otf) format('opentype');
}

@font-face {
    font-family: 'Slightpay-bold';
    src: local('Slightpay-bold'), url(../assets/fonts/gilroy-medium.otf) format('opentype');
}